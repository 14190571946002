import { __decorate } from "tslib";
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Circle, Button } from 'vant';
import { Action, Getter } from 'vuex-class';
import { getPlatform } from '@/utils/util';
let ResultVue = class ResultVue extends Vue {
  constructor() {
    super(...arguments);
    this.countdown = 60 * 60 * 1000;
    this.currentRate = ref(0);
    this.ix = getPlatform().isPhoneX;
  }
  get isPC() {
    return getPlatform().isPC;
  }
  get durationText() {
    const m = Math.floor(this.data.duration / 1000 / 60);
    const s = Math.floor(this.data.duration / 1000 % 60);
    return (m > 10 ? m : `0${m}`) + '分' + (s > 10 ? s : `0${s}`) + '秒';
  }
  async created() {
    const loading = this.$toast.loading({
      forbidClick: true,
      duration: 0
    });
    const id = parseInt(this.$route.params.id);
    await this.getQuestionAnalyze(id);
    if (this.data.title) document.title = this.data.title;
    loading.close();
  }
};
__decorate([Getter('Result/data')], ResultVue.prototype, "data", void 0);
__decorate([Action('Result/getQuestionAnalyze')], ResultVue.prototype, "getQuestionAnalyze", void 0);
ResultVue = __decorate([Options({
  components: {
    [Circle.name]: Circle,
    [Button.name]: Button
  }
})], ResultVue);
export default ResultVue;